export const
  FAULT = 0b10000000,
  RUN = 0b01000000,
  ALARM = 0b00100000,
  ATTENTION = 0b00001000_00000000,
  MOTION = 0b00010000_00000000,
  RECORD = 512,
  RECORD_EVENT = 32768,
  RECORD_FORCED = 65536


export function getDeviceState(stateArr) {
  return Array.isArray(stateArr) && stateArr.length > 0 ? stateArr[0] : 0;
}

export function getStreamChannelState(stateArr, chanNo) {
  return Array.isArray(stateArr) && stateArr.length > chanNo + 1 ? stateArr[chanNo] : 0;
}

export function getAggregatedState(stateArr) {
  let agrSt = 0;
  if (Array.isArray(stateArr)) {
    stateArr.forEach(state => {
      agrSt |= state;
    });
  }

  return agrSt;
}

export function hasFault(state) {
  if (Array.isArray(state)) {
    state = getAggregatedState(state);
  }
  return !!(state & FAULT)
}

export function hasRun(state) {
  if (Array.isArray(state)) {
    state = getAggregatedState(state);
  }
  return !!(state & RUN)
}

export function hasAlarm(state) {
  if (Array.isArray(state)) {
    state = getAggregatedState(state);
  }
  return !!(state & ALARM)
}

export function hasMotion(state) {
  if (Array.isArray(state)) {
    state = getAggregatedState(state);
  }
  return !!(state & MOTION)
}

export function isResetMotion(state) {
  if (Array.isArray(state)) {
    state = getAggregatedState(state);
  }
  return !!(state & (MOTION | 1));
}

export function toAttention(state) {
  return !!(state & (ATTENTION | ALARM | MOTION)) && (state & 1) === 0;
}

export function hasAttention(state) {
  if (Array.isArray(state)) {
    state = getAggregatedState(state);
  }
  return !!(state & ATTENTION);
}

export function hasRecord(state) {
  if (Array.isArray(state)) {
    state = getAggregatedState(state);
  }
  return !!(state & RECORD);
}

export function hasRecordEvent(state) {
  if (Array.isArray(state)) {
    state = getAggregatedState(state);
  }
  return !!(state & RECORD_EVENT);
}

export function hasRecordForced(state) {
  if (Array.isArray(state)) {
    state = getAggregatedState(state);
  }
  return !!(state & RECORD_FORCED);
}